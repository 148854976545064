export const images = {
  checkTick: require("./checkmark-circle-outline.png"),
  box: require("./Group 1000001783.png"),
  box1: require("./Group 1000001784.png"),
  box2: require("./Group 1000001785.png"),
  box3: require("./Group 1000001777.png"),
  box4: require("./Ellipse 799.png"),
  IconTick: require("./IconTick.png"),
  logo: require("./home/edge-pages-logo.svg").default,
  sectionSideImg: require("./home/Group 1.png"),
  sectionrightsideImg: require("./home/Group 2.png"),
  topBoostImg: require("./home/edge-logo-6QY72KNE 2.png"),
  avatorImg: require("./home/th (100) 1.png"),
  icon1Home: require("./home/iconsHome/Icon.png"),
  icon2Home: require("./home/iconsHome/Icon (1).png"),
  icon3Home: require("./home/iconsHome/Icon (2).png"),
  icon4Home: require("./home/iconsHome/Icon (3).png"),
  icon5Home: require("./home/iconsHome/Icon (4).png"),
  icon6Home: require("./home/iconsHome/warehouse.png"),
  FeaturedBusiness: require("./home/Frame 7.png"),
  cardSocalMediaImg: require("./home/Business well/th (100) 2 (1).png"),
  instaIcon: require("./home/Business well/skill-icons_instagram.png"),
  fbIcon: require("./home/Business well/logos_facebook.png"),
  twitterIcon: require("./home/Business well/twitter.png"),
  linkidinIcon: require("./home/Business well/linkidin.png"),
  upworkIcon: require("./home/Business well/upwork.png"),
  bestEvent: require("./best Events/eventBest.png"),
  Best1vent: require("./best Events/th (95) 1.png"),
  Best2vent: require("./best Events/th (99) 1.png"),
  Best3vent: require("./best Events/istockphoto-1415056757-170667a 1.png"),
  Best4vent: require("./best Events/th (97) 1.png"),
  FoodDictorySilder: require("./FoodDictory/Rectangle 9.png"),
  FoodDictorySilder1: require("./FoodDictory/Group 6356111.png"),
  FoodDictorySilder2: require("./FoodDictory/Rectangle 7.png"),
  FoodDictorySilder3: require("./FoodDictory/Group 6356108.png"),
  FoodDictorySilder4: require("./FoodDictory/Group 6356115.png"),
  EdgePage1: require("./Edge Page (1)/Mask Group-1.png"),
  EdgePage6: require("./Edge Page (1)/Mask Group.png"),
  EdgePage2: require("./Edge Page (1)/Mask Group-2.png"),
  EdgePage3: require("./Edge Page (1)/Mask Group-3.png"),
  EdgePage4: require("./Edge Page (1)/Mask Group-4.png"),
  EdgePage5: require("./Edge Page (1)/Mask Group (7).png"),

  homeScreen1: require("./Edge Page (2)/Frame 7-1.png"),
  homeScreen2: require("./Edge Page (2)/Frame 7-2.png"),
  homeScreen3: require("./Edge Page (2)/Frame 7.png"),
  // Capture: require("./Capture.PNG"),
  Capture: require("./Capture.png"),
  JoseAntonio: require("./JoseAntonio.png"),
  avantarProfile: require("./Group 389.png"),
  avantarimgCategroy: require("./Ellipse 2630.png"),
  categoryicon: require("./category/Icon (1).png"),
  categoryicon1: require("./category/Icon (2).png"),
  categoryicon2: require("./category/Icon (3).png"),
  imagebusines: require("./pages/Frame 1000008788.png"),
  imgbusinescall: require("./pages/Rectangle 5298 (2).png"),
  imgbtn: require("./pages/Frame 1000002650.png"),
  imgbtn1: require("./pages/Group 1000008733.png"),
  imgbtn2: require("./pages/Group 1000008732 (1).png"),
  imgbtn3: require("./pages/Frame 1000008793.png"),
  menuPage: require("./pages/Frame 1000008787.png"),
  avatarProfile: require("./Profile/avatar.png"),
  check: require("./Profile/check.png"),
  FrameImg: require("./Private data-pana 1.png"),
  FrameImg1: require("./Buffer-amico 1.png"),
  FrameImg2: require("./Frame 1000011706.png"),
  menu: require("./menu.png"),
  reviewImage: require("./review.png"),
  reviewer: require("./reviewer.png"),
  IndeFreelancer: require("./cardsthree/SampleID_Card_Front2.png"),
  IndeFreelancer1: require("./cardsthree/SampleID_Card_Front2 (1).png"),
  IndeFreelancer2: require("./cardsthree/SampleID_Card_Front2 (2).png"),
  IndeFreelancerprofile: require("./cardsthree/Ellipse (1).png"),
  IndeFreelancerprofil1: require("./cardsthree/Ellipse (2).png"),
  IndeFreelancerprofil2: require("./th (100) 1.png"),
  iconbussincesProfile: require("./Icon.png"),
  iconVector: require("./Vector (1).png"),
  //  1000011622
};
//
