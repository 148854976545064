import LazyComponent from "./LazyComponent";
import Layouts from "../layout/Layouts";
import SecondLayout from "../components/SecondLayout/SecondLayout";
// import ManageProfessional from "../screens/ManageProfessional/ManageProfessional";
export const SiteMap = {
  home: {
    title: "home",
    path: "/",
    element: (
      // <Layouts>
      <LazyComponent path="home/home" />
      // </Layouts>
    ),
    description: "home-Page",
  },
  calendar: {
    title: "calendar",
    path: "/calendar",
    element: (
      <Layouts>
        <LazyComponent path="calendar/Calendar" />
      </Layouts>
    ),
  },
  businessDetails: {
    title: "businessDetails",
    path: "/businessDetails/:id",
    element: (
      <Layouts>
        <LazyComponent path="businessProfile/BusinessProfile" />
      </Layouts>
    ),
    description: "businessProfile-Page",
  },
  freelancer: {
    title: "freelancer",
    path: "/freelancers",
    element: (
      <Layouts>
        <LazyComponent path="freelancer/FreelancerList" />
      </Layouts>
    ),
  },
  thanks: {
    title: "thanks",
    path: "/thanks",
    element: (
      <Layouts>
        <LazyComponent path="thanks/thanks" />
      </Layouts>
    ),
  },
  signupBusiness: {
    title: "signup-business",
    path: "/signup-business",
    element: (
      <Layouts>
        <LazyComponent path="auth/signup-business" />
      </Layouts>
    ),
  },
  businessList: {
    title: "businessList",
    path: "/businessList",
    element: (
      <Layouts>
        <LazyComponent path="businessList/BusinessList" />
      </Layouts>
    ),
  },
  businessProfile: {
    title: "businessProfileHome",
    path: "/businessProfile",
    element: (
      <Layouts>
        <LazyComponent path="businessProfile/BusinessProfile" />
      </Layouts>
    ),
    description: "businessProfile-Page",
  },
  createBusinessProfile: {
    title: "createBusinessProfile",
    path: "/createBusinessProfile",
    element: (
      <Layouts>
        <LazyComponent path="businessProfile/createBusinessProfile" />
      </Layouts>
    ),
    description: "businessProfile-Page",
  },
  BusinessWall: {
    title: "BusinessWall",
    path: "/businessWall",
    element: (
      <Layouts>
        <LazyComponent path="businessWall/BusinessWall" />
      </Layouts>
    ),
    description: "BusinessWall-Page",
  },
  eventsBusiness: {
    title: "eventsBusiness",
    path: "/eventsBusiness",
    element: (
      <Layouts>
        <LazyComponent path="eventsBusiness/EventsBusiness" />
      </Layouts>
    ),
    description: "eventsBusiness-Page",
  },
  FoodDrictory: {
    title: "FoodDrictory",
    path: "/restaurants",
    element: (
      <Layouts>
        <LazyComponent path="FoodDrictory/FoodDrictory" />
      </Layouts>
    ),
    description: "eventsBusiness-Page",
  },
  Categories: {
    title: "Categories",
    path: "/categories",
    element: (
      <Layouts>
        <LazyComponent path="categories/Categories" />
      </Layouts>
    ),
    description: "Categories-Page",
  },
  EdgeBusinessesPage: {
    title: "EdgeBusinessesPage",
    path: "/edgeBusinessesPage",
    element: (
      <Layouts>
        <LazyComponent path="EdgeBusinessesPage/EdgeBusinessesPage" />
      </Layouts>
    ),
    description: "EdgeBusinessesPage-Page",
  },
  Profile: {
    title: "Profile",
    path: "/profile",
    element: (
      <Layouts>
        <LazyComponent path="profile/Profile" />
      </Layouts>
    ),
    description: "Profile-Page",
  },
  RestaurantProfile: {
    title: "Restaurant Profile",
    path: "/restaurant-profile",
    element: (
      <Layouts>
        <LazyComponent path="restaurantProfile/Profile" />
      </Layouts>
    ),
    description: "Restaurant Profile",
  },
  ProfessionalProfile: {
    title: "ProfessionalProfile",
    path: "/professionalProfile",
    element: (
      <Layouts>
        <LazyComponent path="ProfessionalProfile/ProfessionalProfile" />
      </Layouts>
    ),
    description: "ProfessionalProfile",
  },
  PricingTable: {
    title: "PricingTable",
    path: "/pricingTable",
    element: (
      <Layouts>
        <LazyComponent path="PricingTable/PricingTable" />
      </Layouts>
    ),
    description: "PricingTable",
  },
  AboutUs: {
    title: "AboutUs",
    path: "/aboutUs",
    element: (
      <Layouts>
        <LazyComponent path="AboutUs/AboutUs" />
      </Layouts>
    ),
    description: "PrivacyPolicy",
  },
  PrivacyPolicy: {
    title: "PrivacyPolicy",
    path: "/privacyPolicy",
    element: (
      <Layouts>
        <LazyComponent path="PrivacyPolicy/PrivacyPolicy" />
      </Layouts>
    ),
    description: "PrivacyPolicy",
  },
  ContactUs: {
    title: "ContactUs",
    path: "/contactUs",
    element: (
      <Layouts>
        <LazyComponent path="ContactUs/ContactUs" />
      </Layouts>
    ),
    description: "ContactUs",
  },
  // ManageProfessional: {
  //   title: "Signup",
  //   path: "/signup",
  //   element: (
  //     <Layouts>
  //       <LazyComponent path="ManageProfessional/ManageProfessional" />
  //     </Layouts>
  //   ),
  //   description: "ManageProfessional",
  // },
  // signup: {
  //   title: "signup",
  //   path: "/signup",
  //   element: <LazyComponent path="auth/signup" />,
  //   description: "signup",
  // },
  signup: {
    title: "signup",
    path: "/signup",
    element: (
      <Layouts>
        <LazyComponent path="auth/signup-edge" />,
      </Layouts>
    ),
    description: "Signup",
  },
  Register: {
    title: "Register",
    path: "/Register",
    element: <LazyComponent path="auth/Register" />,
    description: "Register",
  },
  login: {
    title: "login",
    path: "/login",
    element: <LazyComponent path="auth/login" />,
    description: "login",
  },
  createProfile: {
    title: "createProfile",
    path: "/createProfile",
    element: <LazyComponent path="createProfile/CreateProfile" />,
    description: "CreateProfile",
  },
  upUpcomingEvents: {
    title: "upUpcomingEvents",
    path: "/UpcomingEvents",
    element: <LazyComponent path="UpComingEvents/UpComingEvents" />,
    description: "EventsUpComing",
  },
  createHighlight: {
    title: "createHighlight",
    path: "/createHighlight",
    element: (
      // <Layouts>
      <LazyComponent path="FoodDrictory/InspirationOrder/highlightForm" />
      // </Layouts>
    ),
    description: "businessProfile-Page",
  },

  createSlide: {
    title: "createSlide",
    path: "/createSlide",
    element: (
      // <Layouts>
      <LazyComponent path="FoodDrictory/OurSpecials/createSlide" />
      // </Layouts>
    ),
    description: "businessProfile-Page",
  },
  createEvent: {
    title: "createEvent",
    path: "/createEvent",
    element: (
      <SecondLayout>
        <LazyComponent path="home/createEvent" />,
      </SecondLayout>
    ),
    description: "businessProfile-Page",
  },
  createEmployProfile: {
    title: "createEmployProfile",
    path: "/createEmployProfile",
    element: (
      // <Layouts>
      <LazyComponent path="ProfessionalProfile/createEmployProfile" />
      // </Layouts>
    ),
    description: "businessProfile-Page",
  },
  createRestaurantProfile: {
    title: "createRestaurantProfile",
    path: "/createRestaurantProfile",
    element: (
      // <Layouts>
      <LazyComponent path="restaurantProfile/createRestaurantProfile" />
      // </Layouts>
    ),
    description: "businessProfile-Page",
  },
};
