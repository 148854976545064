import React from "react";
import { Layout } from "antd";
import Headers from "./header/header";
import Footers from "./footer/Footer";

const { Header, Footer, Content } = Layout;
function Layouts({ children }) {
  return (
    <div>
      <Layout>
        <Header>
          <Headers />
        </Header>
        <Content
          style={{
            backgroundColor: "white",
          }}
        >
          {children}
        </Content>
        <Footer
          style={{
            backgroundColor: "white",
          }}
        >
          <Footers />
        </Footer>
      </Layout>
    </div>
  );
}

export default Layouts;
