import { Button } from "antd";
import React from "react";
import { images } from "../../assets";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
export default function Navber() {
  const navigate = useNavigate();
  return (
    <div>
      <header className="main-navbar1">
        <div className="navbar-brand">
          <Link to="/">
            <img
              src={images.logo}
              alt="Edge Logo"
              className="logo"
              style={{ width: "80px", cursor: "pointer" }}
            />
          </Link>
        </div>
        <div>
          <div className="navbar-actions">
            {/* <Button className="login-in-btn" onClick={() => navigate("/login")}>
              Log in
            </Button> */}
            <Button
              type="link"
              className="signup-btn"
              onClick={() => navigate("/signup")}
            >
              Sign Up
            </Button>
          </div>
        </div>
      </header>
    </div>
  );
}
