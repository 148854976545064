import React from "react";
import { Layout } from "antd";
// import Headers from "./header/header";
// import Footers from "./footer/Footer";
import Navber from "./Navber";
import Footers from "../../layout/footer/Footer";
const { Header, Footer, Content } = Layout;
function SecondLayout({ children }) {
  return (
    <div>
      <Layout>
        <Header>
          <Navber />
        </Header>
        <Content
          style={{
            backgroundColor: "white",
          }}
        >
          {children}
        </Content>
        <Footer
          style={{
            backgroundColor: "white",
          }}
        >
          <Footers />
        </Footer>
      </Layout>
    </div>
  );
}

export default SecondLayout;
