import React from "react";
import "../../style/home/footer.scss";
import { FaInstagram } from "react-icons/fa";
import { Divider } from "antd";
import { useLocation, Link } from "react-router-dom";

export default function Footers() {
  const { pathname } = useLocation();
  const socialIcons = [
    // <FaFacebookF key="facebook" size={24} style={{ cursor: "pointer" }} />,
    <a href="https://www.instagram.com/edgepages">
      <FaInstagram key="instagram" size={24} style={{ color: "black" }} />
    </a>,
    // <FaLinkedinIn key="linkedin" size={24} style={{ cursor: "pointer" }} />,
    // <FaTwitter key="twitter" size={24} style={{ cursor: "pointer" }} />,
  ];

  return (
    <>
      <Divider />
      <div className="Footers">
        <h1>Welcome to edge</h1>
        {pathname === "/" && (
          <ul className="footer__nav">
            <li style={{ cursor: "pointer" }}>
              <Link to="/aboutUs">About Us</Link>
            </li>
            <li style={{ cursor: "pointer" }}>
              <Link to="/privacyPolicy">Privacy Policy</Link>
            </li>
            <li style={{ cursor: "pointer" }}>
              <a href="https://www.instagram.com/edgepages">Contact Us</a>
              {/* <Link to="/contactUs">Contact Us</Link> */}
            </li>
            <li style={{ cursor: "pointer" }}>
              <Link to="/categories">Categories</Link>
            </li>
          </ul>
        )}
        <Divider />
        <div className="inner-icons">{socialIcons.map((icon) => icon)}</div>
      </div>
    </>
  );
}
