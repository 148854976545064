import React, { useState } from "react";
import "../../style/home/header.scss";
import { images } from "../../assets";
import { Button } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import { useNavigate, Link } from "react-router-dom";
const navItems = [
  { title: "Home", url: "/" },
  { title: "Business", url: "/businessList" },
  { title: "Freelancers", url: "/freelancers" },
  { title: "Restaurants", url: "/restaurants" },
  { title: "Business Wall", url: "/businessWall" },
];
export default function Headers() {
  const [activeItem, setActiveItem] = useState(navItems[0]);
  const [menuOpen, setMenuOpen] = useState(false);

  const navigate = useNavigate();
  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className="main-navbar">
      <div className="container-navber">
        <div className="main-logo">
          <div className="navbar-brand">
            <Link to="/">
              <img
                src={images.logo}
                alt="Edge Logo"
                className="logo"
                style={{ width: "80px", marginTop: "1.5rem" }}
              />
            </Link>
          </div>
        </div>
        <nav className={`navbar-nav ${menuOpen ? "active" : ""}`}>
          <ul className="nav-list">
            {navItems.map((item, index) => (
              <li key={index}>
                <NavLink to={item.url}>{item.title}</NavLink>
              </li>
            ))}
          </ul>
        </nav>
        <div className="navbar-actions">
          {/* <Button className="login-in-btn" onClick={() => navigate("/login")}>
            Log in
          </Button> */}
          <Button
            type="link"
            className="signup-btn"
            onClick={() => navigate("/signup")}
          >
            Sign Up
          </Button>
        </div>
        <div className="menu-icon" onClick={handleMenuToggle}>
          <MenuOutlined />
        </div>
      </div>
    </header>
  );
}
