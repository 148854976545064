import React from "react";
// import Layouts from "./layout/Layouts";
// import BoostSection from "./components/home/sectionBoost/SectionBoost";
// import EdgeBusiness from "./components/home/edgeBusinesses/EdgeBusiness";
// import BusinessCard from "./components/BusinessCard/BusinessCard";
// import Home from "./screens/home/home";
import { RouterProvider } from "react-router-dom";
import { router } from "./navigation/Router";

function App() {
  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
